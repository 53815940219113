import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestData } from '../../request';
import { message } from 'antd';
import { getSession } from '../../../lib/Auth';

export const AllOfLeads = createAsyncThunk(
  'all/leads',
  async (_, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/leads/all_leads`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

//all leads
const AllLeadsSlice = createSlice({
  name: 'allleads',
  initialState: {
    allleads: [],
    meta: null,
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      // All Leads
      .addCase(AllOfLeads.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AllOfLeads.fulfilled, (state, action) => {
        state.loading = false;
        state.allleads = action.payload?.leads;
        state.meta = action.payload?.meta;
      })
      .addCase(AllOfLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch all leads ';
      });
  }
});

export default AllLeadsSlice.reducer;
