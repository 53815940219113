import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestData } from '../../request';
import { message } from 'antd';
import { getSession } from '../../../lib/Auth';

export const AllUsersAccounts = createAsyncThunk(
  'all/users',
  async ({ page, pageSize }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const queryParams = new URLSearchParams({
        page: page.toString(),
        pageSize: pageSize.toString()
      }).toString();
      const data = await requestData(
        {
          url: `api/v1/users/admins_and_agents?${queryParams}`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

export const AllUsersAccountsSearch = createAsyncThunk(
  'all/users/search',
  async ({ params, page, pageSize }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const queryParams = new URLSearchParams({
        page: page.toString(),
        pageSize: pageSize.toString(),
        params: params
      }).toString();
      const data = await requestData(
        {
          url: `api/v1/users/admins_and_agents?${queryParams}`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

export const updateAccount = createAsyncThunk(
  'account/update',
  async ({ id, agentData }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/agencies/${id}/update_status`,
          method: 'PATCH',
          data: agentData,
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(agentData);
      } else {
        message.success('Account updated successfully');
        return agentData;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

//all users
const AllUsersSlice = createSlice({
  name: 'allusers',
  initialState: {
    allusers: [],
    meta: null,
    loading: false,
    error: null
  },
  reducers: {
    UpdateInallUserlist: (state, action) => {
      state.allusers = state.allusers.map((agent) =>
        agent.id === action.payload.agent?.id
          ? { ...agent, ...action.payload.agent }
          : agent
      );
    }
  },
  extraReducers: (builder) => {
    builder

      // All Users
      .addCase(AllUsersAccounts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AllUsersAccounts.fulfilled, (state, action) => {
        state.loading = false;
        state.allusers = action.payload?.users;
        state.meta = action.payload?.meta;
      })
      .addCase(AllUsersAccounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch all users ';
      })
      .addCase(AllUsersAccountsSearch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AllUsersAccountsSearch.fulfilled, (state, action) => {
        state.loading = false;
        state.allusers = action.payload?.users;
        state.meta = action.payload?.meta;
      })
      .addCase(AllUsersAccountsSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to fetch all users ';
      })
      .addCase(updateAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.allusers = state.allusers.map((agent) =>
          agent.id === action.payload?.user_id
            ? { ...agent, status: action.payload?.status }
            : agent
        );
      })
      .addCase(updateAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to update agent';
      });
  }
});
export const { UpdateInallUserlist } = AllUsersSlice.actions;
export default AllUsersSlice.reducer;
