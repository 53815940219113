import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { requestData } from '../../request';
import { message } from 'antd';
import { getSession } from '../../../lib/Auth';

// Create an contract Schedule
export const createContractSchedule = createAsyncThunk(
  'contracts/Schedules/create',
  async ({ contractId, contractScheduleData }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/contracts/${contractId}/schedules`,
          method: 'POST',
          data: contractScheduleData,
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        message.success('Schedule created successfully');
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// List all contracts Schedules
export const listContractsSchedules = createAsyncThunk(
  'contracts/list/schedules',
  async ({ contractId, page, pageSize }, { rejectWithValue }) => {
    try {
      const token = await getSession(); // Ensure getSession is correctly retrieving the token
      if (!token?.token) {
        throw new Error('Authentication token not found');
      }

      const queryParams = new URLSearchParams({
        page: page.toString(),
        per_page: pageSize.toString()
      }).toString();

      const data = await requestData(
        {
          url: `api/v1/contracts/${contractId}/schedules?${queryParams}`,
          method: 'GET',
          token: token.token
        },
        { rejectWithValue }
      );

      if (!data || typeof data !== 'object') {
        return rejectWithValue('Invalid API response');
      }

      if (typeof data.payload === 'string') {
        message.destroy(); // Prevent message stacking
        message.error(data.payload);
        return rejectWithValue(data.payload);
      }

      return data; // Successful API response
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ||
        error.message ||
        'An unknown error occurred';
      message.destroy();
      message.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

// List all contracts Schedules search
export const listContractsSchedulesSearch = createAsyncThunk(
  'contracts/list/schedules/search',
  async ({ contractId, name }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/contracts/${contractId}/schedules?search=${name}`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// List all contracts schedules
export const listContractsSchedulesLeads = createAsyncThunk(
  'contracts/list/schedules/leads',
  async (_, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: 'api/v1/leads_fields',
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// List all contracts schedules Values
export const listContractsSchedulesLeadsValues = createAsyncThunk(
  'contracts/list/schedules/leads/values',
  async (id, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/leads_fields/${id}/schedule_values`,
          method: 'GET',
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// Update an contract Schedule
export const updateContractSchedule = createAsyncThunk(
  'contracts/schedules/update',
  async ({ contractId, id, contractSchedulesData }, { rejectWithValue }) => {
    try {
      const token = getSession();
      const data = await requestData(
        {
          url: `api/v1/contracts/${contractId}/schedules/${id}`,
          method: 'PUT',
          data: contractSchedulesData,
          token: token?.token
        },
        { rejectWithValue }
      );
      if (typeof data?.payload === 'string') {
        message.error(data?.payload);
        return rejectWithValue(data?.payload);
      } else {
        message.success('Schedule updated successfully');
        return data;
      }
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// Delete an contracts Schedule
export const deleteContractSchedule = createAsyncThunk(
  'contracts/Schedule/delete',
  async ({ contractid, id }, { rejectWithValue }) => {
    try {
      const token = getSession();
      await requestData(
        {
          url: `api/v1/contracts/${contractid}/schedules/${id}`,
          method: 'DELETE',
          token: token?.token
        },
        { rejectWithValue }
      );
      message.success('Schedule deleted successfully');
      return id;
    } catch (error) {
      message.error(error);
      return rejectWithValue(error);
    }
  }
);

// contracts slice Schedule
const contractsSchedulesSlice = createSlice({
  name: 'schedules',
  initialState: {
    contractsschedules: [],
    meta: null,
    schedule: null,
    leads: [],
    values: [],
    loading: false,
    error: ''
  },
  reducers: {
    ContractScheduleCreate: (state, action) => {
      state.schedule = action.payload.schedule;
    }
  },
  extraReducers: (builder) => {
    builder
      // Create contract schedules
      .addCase(createContractSchedule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createContractSchedule.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.schedule = action.payload.schedule;
        state.contractsschedules = [
          action.payload.schedule,
          ...state.contractsschedules
        ];
      })

      .addCase(createContractSchedule.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload || 'Failed to create Schedule';
      })

      // List contracts Schedules
      .addCase(listContractsSchedules.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listContractsSchedules.fulfilled, (state, action) => {
        state.loading = false;
        state.contractsschedules = action.payload?.schedules;
        state.meta = action.payload?.meta;
      })
      .addCase(listContractsSchedules.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload || 'Failed to fetch contract schedules';
      })

      // List contracts search
      .addCase(listContractsSchedulesSearch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listContractsSchedulesSearch.fulfilled, (state, action) => {
        state.loading = false;
        state.contractsschedules = action.payload?.schedules;
        state.meta = action.payload?.meta;
      })
      .addCase(listContractsSchedulesSearch.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload || 'Failed to fetch contract schedules';
      })

      // List contracts of leads
      .addCase(listContractsSchedulesLeads.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listContractsSchedulesLeads.fulfilled, (state, action) => {
        state.loading = false;
        state.leads = action.payload?.fields;
      })
      .addCase(listContractsSchedulesLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload || 'Failed to fetch leads';
      })

      // List contracts of leads values
      .addCase(listContractsSchedulesLeadsValues.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listContractsSchedulesLeadsValues.fulfilled, (state, action) => {
        state.loading = false;
        state.values = action.payload?.values;
      })
      .addCase(listContractsSchedulesLeadsValues.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload || 'Failed to fetch leads values';
      })

      // Update Contract Schedule
      .addCase(updateContractSchedule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateContractSchedule.fulfilled, (state, action) => {
        state.loading = false;
        state.contractsschedules = state.contractsschedules.map((schedule) =>
          schedule.id === action.payload.schedule?.id
            ? { ...schedule, ...action.payload.schedule }
            : schedule
        );
        state.schedule = action.payload.schedule;
      })
      .addCase(updateContractSchedule.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload || 'Failed to update contract schedule';
      })

      // Delete contract Schedule
      .addCase(deleteContractSchedule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteContractSchedule.fulfilled, (state, action) => {
        state.loading = false;
        state.contractsschedules = state.contractsschedules.filter(
          (schedule) => schedule.id !== action.payload
        );
      })
      .addCase(deleteContractSchedule.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload || 'Failed to delete contract Schedule';
      });
  }
});

export const { ContractScheduleCreate } = contractsSchedulesSlice.actions;
export default contractsSchedulesSlice.reducer;
